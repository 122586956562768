let loader = window.localStorage.getItem('loader')

import noUiSlider from 'nouislider';
import LazyLoad from 'vanilla-lazyload';


$('#loader audio').get(0).pause()
if (loader === null) {
    $('#loader').fadeIn().css('display', 'grid');
    $(window).on('load', function () {
        $('#loader audio').get(0).play()
        $('#loader #aiguille').addClass('rotation')
        window.localStorage.setItem('loader', 1)
        let loaderClose = setTimeout(function () {
            $("#loader").fadeOut()
        }, 1250);
    })
}
$(document).ready(function () {
    /*
    STICKY MENU
    */
    $('header').sticky({
        topSpacing: 0,
        zIndex: 50
    });

    /* Slider range prix */
    if ($('#slider-range').length > 0) {
        let range = document.getElementById('slider-range');
        let prixMin = $('#prixMinData').data('prix');
        let prixMax = $('#prixMaxData').data('prix');
        let prixMinFiltres = $('#prixMinFiltres').data('prix');
        let prixMaxFiltres = $('#prixMaxFiltres').data('prix');
        noUiSlider.create(range, {
            range: {
                'min': prixMin,
                'max': prixMax
            },
            step: 100,
            start: [prixMinFiltres, prixMaxFiltres],
            connect: true,
            format: wNumb({
                decimals: 0,
            })
        })
        let inputs = [
            document.getElementById('search_prixMin'),
            document.getElementById('search_prixMax')
        ];

        range.noUiSlider.on('start', function (values, handle) {
            inputs[handle].value = values[handle];
        });
        range.noUiSlider.on('update', function (values, handle) {
            inputs[handle].value = values[handle];
        });
    }

    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function () {
        /*Ouvre le menu*/
        $('#nav').slideToggle();

        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();
    })
    /* Ouverture des sous-menu */
    if ($(window).width() < 1025) {
        $('#nav > ul > li > p').on('click', function () {
            if ($(this).siblings('ul').length > 0) {
                $(this).siblings('ul').slideToggle();
            }
        })
    }

    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
        let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="' + title + '"]').removeClass('hidden');
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-' + id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').each(function () {
        $(this).on('click', function (e) {
            e.preventDefault()
            $(this).closest('.popup').fadeOut();
        })
    })
    $('.popup').on('click', function (e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('.popup, #gallery').fadeOut();
            $('body').css('overflow-y', 'scroll')
        }
    });

    /*
    ASIDE
     */
    $('aside > div > div').on('click', function () {
        $(this).parent().toggleClass('transform translate-x-0')
    })
    /*
Popup aide
 */
    let popupTimeOutNombreSecondes = 10;
    let popupTimeOut = null;
    let popupAffichee = false;

    function popupSetTimeOut() {
        clearTimeout(popupTimeOut);
        let popupTimeOut = setTimeout(function () {
            $("#contact-toggle").parent().fadeIn().css('display', 'flex');
        }, popupTimeOutNombreSecondes * 1000);
    }

    if ($('#contact-toggle').length > 0) {
        $(document).mousemove(function () {
            if (!popupAffichee) {
                popupAffichee = true;
                clearTimeout(popupTimeOut);
                popupSetTimeOut();
            }
        });
    }

    /* Focus */
    $('#garage section h3').addClass('dot')
    $('.dot').each(function () {
        $(this).on('mouseenter', function () {
            $(this).prev().addClass('active')
        }).on('mouseleave', function () {
            $(this).prev().removeClass('active')
        })
    })
    if ($(window).width() < 1024) {
        $('.dot').css({
            'clip-path': 'none',
            'padding': '0 1rem'
        })
    }

    if ($(window).width() > 1024) {
        $('#toggle-view').on('click', function () {
            $('#v-list').toggleClass('wide-view')
            $('#toggle-view span').toggleClass('hidden')
            $('#toggle-view .btn-view').toggleClass('active')
            $('#toggle-view svg').toggleClass('text-primary').toggleClass('text-white')
        })
    }

    if ($(window).width() > 1024) {
        $('details summary').on('click', function () {
            $(this).toggleClass('active')
        })
    }

    var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
    });

    /* Partage réseaux sociaux */
    $('#partage-rs').on('mouseenter', function () {
        $(this).find('div').slideDown();
    }).on('mouseleave', function () {
        $(this).find('div').slideUp();
    });
    // Ecran tactiles
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
        $('#partage-rs > a').unbind('click').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('div').slideToggle();
        });
    }

    /* Gallery photo véhicule */
    $('.open-gallery').on('click', function (e) {
        e.preventDefault();
        $('#gallery').fadeIn()
        $('body').css('overflow-y', 'hidden')
        const id = $(this).data('id');
        if (id !== '' && id !== undefined) {
            swiperGalleryMain.slideTo(id-1);

        }
    })
    $('#close-gallery').on('click', function (e) {
        e.preventDefault();
        $('#gallery').fadeOut()
        $('body').css('overflow-y', 'scroll')
    })

    $('#slider-gallery-main .swiper-slide a').colorbox({
        width: '95%',
        height: '95%',
        rel: 'gal'
    })

    /*
    Infos reprise
     */
    $('#reprise').on('change', function () {
        if ($('#reprise').is(':checked')) {
            $('#reprise-infos').slideDown().css('display', 'flex');
            $('#reprise-infos input').prop('required', true);
            $('#reprise-infos textarea').prop('required', true);

        } else {
            $('#reprise-infos').slideUp();
            $('#reprise-infos input').prop('required', false);
            $('#reprise-infos textarea').prop('required', false);
        }
    })

    /* Vidéos équipe */
    $('#slider-equipe figure button').on('click', function () {
        let data = $(this).parent().data('nom')
        $(this).colorbox({
            inline: true,
            href: "#video-" + data,
            onOpen: function (){
                $("#video-" + data).show()
                $('html').addClass('overflow-hidden')
            },
            onCleanup: function (){
                $("#video-" + data).hide()
                $('html').removeClass('overflow-hidden')
            },
        });

    })

    /* Note avis */
    $('.remove-qt').on('click', function (e) {
        e.preventDefault();
        $('.add-qt').removeClass('opacity-75');
        let inputNote = $('#avis_note');
        let noteActuelle = parseInt(inputNote.val());
        if (noteActuelle > 0) {
            inputNote.val(noteActuelle - 1);
            if(noteActuelle - 1 === 0) $('.remove-qt').addClass('opacity-75');
        }
    })
    $('.add-qt').on('click', function (e) {
        e.preventDefault();
        $('.remove-qt').removeClass('opacity-75');
        let inputNote = $('#avis_note');
        let noteActuelle = parseInt(inputNote.val());
        let noteMax = parseInt(inputNote.attr("max"));
        if (!noteMax) noteMax = 10;
        if (noteActuelle < noteMax) {
            inputNote.val(noteActuelle + 1);
            if(noteActuelle + 1 >= 10) $('.add-qt').addClass('opacity-75');
        }
    })

    $(".video-link").colorbox(
        {
            iframe:true,
            width: '1850px',
            height: '950px',
            maxWidth: '90%',
            maxHeight: '90%',
        }
    );


});

/*
SLIDERS
 */
import Swiper, {Autoplay, FreeMode, Lazy, Navigation, Pagination, Thumbs} from 'swiper';
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";


Swiper.use([Navigation, Pagination, Thumbs, Autoplay, FreeMode, Lazy]);

const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    autoplay: true,
    navigation: {
        nextEl: '.swiper-hero_next',
        prevEl: '.swiper-hero_prev',
    },
    breakpoints: {
        1280: {
            direction: "vertical",
            pagination: {
                el: '.swiper-hero_pagination',
                clickable: true
            },
        }
    }
})

const swiperTestThumbs = new Swiper('#slider-test-thumbs', {
    loop: true,
    slidesPerView: 3,
    centeredSlides: true,
    spaceBetween: 50,
    watchSlidesProgress: true,
    breakpoints: {
        1024: {
            direction: "vertical",
        }
    }
})

const swiperTestMain = new Swiper('#slider-test-main', {
    loop: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    thumbs: {
        swiper: swiperTestThumbs,
    },
    navigation: {
        nextEl: ".swiper-test_next",
        prevEl: ".swiper-test_prev",
    },
    breakpoints: {
        1280: {
            direction: "vertical",
        }
    }
})

const swiperEquipe = new Swiper('#slider-equipe', {
    loop: true,
    lazy: {
        loadPrevNext: true,
    },
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    loadPrevNextAmount: 3,
    centeredSlides: true,
    navigation: {
        nextEl: ".swiper-equipe_next",
        prevEl: ".swiper-equipe_prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 25
        },
        1024: {
            slidesPerView: 3,
        },
        1280: {
            slidesPerView: 3,
            autoplay: {
                delay: 1,
                disableOnInteraction: true,
            },
            freeMode: true,
            speed: 5000,
            freeModeMomentum: false,
        },
        1600: {
            slidesPerView: 3,
            spaceBetween: 50
        }
    }
})
if ($(window).width() >= 1024) {
    $("#slider-equipe figure").on('mouseenter', function () {
        swiperEquipe.autoplay.stop();
        let nom = $(this).data('nom')

        $('#text-equipe').fadeIn()
        $('#text-equipe').find("div[data-text='" + nom + "']").fadeIn()
    }).on('mouseleave', function () {
        swiperEquipe.autoplay.start();
        $('#text-equipe').hide()
        $('#text-equipe div').hide()
    });
    $("#slider-equipe").on('mouseleave', function () {
        swiperEquipe.autoplay.start();
        $('#text-equipe').hide()
        $('#text-equipe div').hide()
    });
} else {
    swiperEquipe.on('transitionEnd', function () {
        let nom = $('#slider-equipe .swiper-slide-active figure').data('nom');
        $('#text-equipe div').fadeOut()
        setTimeout(() => {
            $('#text-equipe').find("div[data-text='" + nom + "']").fadeIn()
        }, 150);
    });

}

const swiperReseaux = new Swiper('#slider-reseaux', {
    preloadImages: false,
    lazy: {
        loadPrevNext: true,
    },
    loop: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    navigation: {
        nextEl: ".swiper-reseaux_next",
        prevEl: ".swiper-reseaux_prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 3
        },
        1280: {
            slidesPerView: 2
        },
        1600: {
            slidesPerView: 3,
        }
    }
})

const swiperGalleryThumbs = new Swiper('#slider-gallery-thumbs', {
    // loop: true,
    slidesPerView: 5,
    direction: "vertical",
    // centeredSlides: true,
    spaceBetween: 25,
    slideToClickedSlide: true,
    runCallbacksOnInit: true,
    observer: true,
    watchSlidesProgress: true,

})



const swiperGalleryMain = new Swiper('#slider-gallery-main', {
    // loop: true,

    slideToClickedSlide: true,
    runCallbacksOnInit: true,
    observer: true,
    centeredSlides: true,
    thumbs: {
        swiper: swiperGalleryThumbs,
    },
    navigation: {
        nextEl: ".swiper-gallery_next",
        prevEl: ".swiper-gallery_prev",
    },
    // breakpoints: {
    //     1024: {
    //         direction: "vertical",
    //     }
    // }
})

const swiperRecommendations = new Swiper('#slider-recommendations', {
    loop: true,
    slideToClickedSlide: true,
    runCallbacksOnInit: true,
    observer: true,
    navigation: {
        nextEl: ".swiper-recommendations_next",
        prevEl: ".swiper-recommendations_prev",
    },
    breakpoints: {
        780: {
            slidesPerView: 2,
            centeredSlides: false,
            spaceBetween: 25
        },
        1280: {
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween: 50
        }
    }
})
if ($('#slider-recommendations').length > 0) {
    swiperRecommendations.loopDestroy();
}

const swiperArticleThumbs = new Swiper('#slider-article-thumbs', {
    loop: true,
    lazy: {
        loadPrevNext: true,
    },
    watchSlidesProgress: true,
    centeredSlides: true,
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 25,
        },
        780: {
            slidesPerView: 3,
        },
        1280: {
            slidesPerView: 5,
            direction: "vertical",
            spaceBetween: 10,
        }
    }
})

const swiperArticleMain = new Swiper('#slider-article-main', {
    loop: true,
    lazy: {
        loadPrevNext: true,
    },
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    autoplay: true,
    thumbs: {
        swiper: swiperArticleThumbs,
    },
    navigation: {
        nextEl: ".swiper-article_next",
        prevEl: ".swiper-article_prev",
    },
})


gsap.registerPlugin(ScrollTrigger);
gsap.to('#progress div', {
    width: '100%',
    ease: 'none',
    scrollTrigger: {
        scrub: 0.3
    }
});

/* Marquee */

let tween = gsap.to(".line", {xPercent: -100, repeat: -1, duration: 25, ease: "none"})

/* Histoire + Témoignages + MVApprouved*/
function animateFrom(elem, direction) {
    direction = direction || 1;
    let x = 0,
        y = direction * 100;
    if (elem.classList.contains("fromLeft")) {
        x = -100;
        y = 0;
    } else if (elem.classList.contains("fromRight")) {
        x = 100;
        y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
    });
}

function fadeIn(elem) {
    gsap.to(elem, {
        duration: 1,
        opacity: 1,
        autoAlpha: 1,
        stagger: .75,
    })

}

function hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
}

document.addEventListener("DOMContentLoaded", function () {

    /* Notre histoire */
    if ($(window).width() > 1024) {
        $('#timeline section:nth-of-type(odd)').addClass('fromLeft')
    } else {
        $('#timeline section:nth-of-type(odd)').addClass('fromRight')
    }
    $('#timeline section:nth-of-type(even)').addClass('fromRight')

    gsap.utils.toArray("#timeline section").forEach(function (elem) {
        hide(elem);

        ScrollTrigger.create({
            trigger: elem,
            start: 'top center',
            onEnter: function () {
                animateFrom(elem)
            },
            onUpdate: self => {
                gsap.to("#car", {rotation: () => self.direction === 1 ? 0 : -180, overwrite: 'auto'});
            }
        });
    });

    let car = gsap.timeline({
        scrollTrigger: {
            trigger: "#timeline",
            pin: '#car',
            scrub: 1,
            start: 'top center',
            end: "bottom center"
        }
    }).to('#car', {
        duration: 1,
        ease: 'none',
    })

    /* MVApprouved */
    let item = $('.mvapprouved *')
    gsap.to(item, {
        duration: .5,
        opacity: 1,
        stagger: .75,
    })

    /* Notes */
    let notes = $('.notes i')
    gsap.to(notes, {
        duration: 1,
        opacity: 1,
        stagger: .25,
    })

    /* Compteur likes */
    const count = $(".data");
    gsap.from(count, {
        textContent: 0,
        duration: 2,
        snap: {textContent: 1},
        stagger: .25,
    });

    /* Infos */
    if ($('#showroom .dot ~ *').length) {
        let infos = $('#showroom .dot ~ *')
        ScrollTrigger.create({
            trigger: "#showroom",
            start: "center 90%",
            scrub: true,
            onEnter: function () {
                fadeIn(infos)
            },
        });
    }
    if ($('#garage h3 ~ *, #garage a ~ *').length) {
        let infos = $('#garage h3 ~ *, #garage a ~ *')
        ScrollTrigger.create({
            trigger: "#garage",
            start: "center 90%",
            scrub: true,
            onEnter: function () {
                fadeIn(infos)
            },
        });
    }


});

$(document).ready (function () {
    /*Filtres photos sur galerie*/
    $('#galerieTags a').on('click', function (e) {
        e.preventDefault();
        $('#galerieTags .tag-actif').removeClass('tag-actif');
        $(this).addClass('tag-actif');
        let tag = $(this).data('tag');

        if(tag > 0) {
            $('#slider-gallery-main .swiper-slide').css('display', 'none').removeClass('swiper-slide').addClass('swiper-hidden-slide');
           $('#slider-gallery-main .swiper-hidden-slide.galerie-img-tag-'+tag).css('display', '').addClass('swiper-slide').removeClass('swiper-hidden-slide');

            $('#slider-gallery-thumbs .swiper-slide').css('display', 'none').removeClass('swiper-slide').addClass('swiper-hidden-slide');
            $('#slider-gallery-thumbs .swiper-hidden-slide.galerie-img-tag-'+tag).css('display', '').addClass('swiper-slide').removeClass('swiper-hidden-slide');

            swiperGalleryMain.init()
            swiperGalleryMain.update()
            swiperGalleryMain.updateSize();
            swiperGalleryMain.updateSlides();
            swiperGalleryMain.updateProgress();
            swiperGalleryMain.updateSlidesClasses();
            swiperGalleryMain.slideTo(0);

            swiperGalleryThumbs.init()
            swiperGalleryThumbs.update()
            swiperGalleryThumbs.updateSize();
            swiperGalleryThumbs.updateSlides();
            swiperGalleryThumbs.updateProgress();
            swiperGalleryThumbs.updateSlidesClasses();
            swiperGalleryThumbs.slideTo(0);
            $('#slider-gallery-thumbs .swiper-slide.swiper-slide-active').addClass('swiper-slide-thumb-active');


        } else {
            $('#slider-gallery-main .swiper-slide').fadeIn();
            $('#slider-gallery-main .swiper-hidden-slide').addClass('swiper-slide').fadeIn().removeClass('swiper-hidden-slide');
            $('#slider-gallery-thumbs .swiper-slide').fadeIn();
            $('#slider-gallery-thumbs .swiper-hidden-slide').addClass('swiper-slide').fadeIn().removeClass('swiper-hidden-slide');

            swiperGalleryMain.init()
            swiperGalleryMain.update()
            swiperGalleryMain.updateSize();
            swiperGalleryMain.updateSlides();
            swiperGalleryMain.updateProgress();
            swiperGalleryMain.updateSlidesClasses();
            swiperGalleryMain.slideTo(0);

            swiperGalleryThumbs.init()
            swiperGalleryThumbs.update()
            swiperGalleryThumbs.updateSize();
            swiperGalleryThumbs.updateSlides();
            swiperGalleryThumbs.updateProgress();
            swiperGalleryThumbs.updateSlidesClasses();
            swiperGalleryThumbs.slideTo(0);
            $('#slider-gallery-thumbs .swiper-slide').removeClass('swiper-slide-thumb-active');
            $('#slider-gallery-thumbs .swiper-slide.swiper-slide-active').addClass('swiper-slide-thumb-active');

        }

        $('#gallery').fadeIn()
        $('body').css('overflow-y', 'hidden')
    })
})

/**
 * Bouton voir plus
 * @param bouton
 * @param object param : url, filters, frame
 */
const nbVehiculesTotal =  $('#listing-vehicule').data('total');
window.seeMore = function(bouton, param) {
    if($(bouton) == undefined || param == undefined) return;
    $(bouton).css("display", "none");

    // Offset + limit
    var offset = 0;
    if($(bouton).attr("data-offset") != undefined) offset = parseFloat($(bouton).attr("data-offset"));
    var limit = 0;
    if($(bouton).attr("data-limit") != undefined) limit = parseFloat($(bouton).attr("data-limit"));
    var list = '';
    //Liste des véhicules déjà affichés
    if($(bouton).attr("data-list") != undefined && $(bouton).attr("data-list") != "") list = $(bouton).attr("data-list");

    var filters = param.filters;

    $.ajax({
        url: param.url,
        method: 'post',
        data: {filters:filters, offset:offset, limit:limit, list:list},
        success: function(data){
            if(data == undefined || data == "") return false;
            $('#' + param.frame).append(data);
            $(bouton).attr("data-offset", offset + limit);
            const listV = $('#v-list > section:last-of-type').data('list');
            $(bouton).attr("data-list", listV);
            if (nbVehiculesTotal > offset + limit) {
                $(bouton).css("display", "block");
            }
        }
    });
}